var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"category_lists"},[_c('div',{staticClass:"ls-card"},[_c('div',{staticClass:"m-t-20"},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true,"model":_vm.searchForm,"size":"small"}},[_c('el-form-item',{attrs:{"label":"类型"}},[_c('el-cascader',{attrs:{"options":_vm.type_list,"props":{
								checkStrictly: true,
								value: 'id',
								label: 'name',
								children: 'sons',
								emitPath: false
							},"clearable":""},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return (data.level <= 2)?[_c('span',[_vm._v(_vm._s(data.name))]),(!node.isLeaf)?_c('span',[_vm._v(" ("+_vm._s(data.sons.length)+") ")]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.searchForm.type_id),callback:function ($$v) {_vm.$set(_vm.searchForm, "type_id", $$v)},expression:"searchForm.type_id"}})],1),_c('el-form-item',{attrs:{"label":"标签"}},[_c('el-select',{attrs:{"placeholder":"全部"},model:{value:(_vm.searchForm.tag),callback:function ($$v) {_vm.$set(_vm.searchForm, "tag", $$v)},expression:"searchForm.tag"}},_vm._l((_vm.goods_tags),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{staticClass:"m-l-24"},[_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":_vm.onSearch}},[_vm._v("查询 ")])],1)],1)],1)]),_c('div',{staticClass:"ls-card m-t-24"},[_c('div',{staticClass:"add-btn"},[_c('sdvideo-goods-edit',{attrs:{"title":"新增"},on:{"refresh":_vm.getList}},[_c('el-button',{attrs:{"slot":"trigger","type":"primary","size":"mini"},slot:"trigger"},[_vm._v("新增")])],1)],1),_c('div',{staticClass:"m-t-24"},[_c('u-table',{ref:"plTreeTable",attrs:{"data":_vm.pager.lists,"fixed-columns-roll":"","height":800,"treeConfig":{
						children: 'sons',
						expandAll: false
					},"use-virtual":"","row-id":"id","border":false,"size":"mini"}},[_c('u-table-column',{attrs:{"label":"图片"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.type == 'video')?_c('div',{staticClass:"flex"},[_c('video',{staticStyle:{"width":"58px","height":"58px"},attrs:{"src":scope.row.all_url,"controls":""}})]):_c('div',{staticClass:"flex"},[_c('el-image',{staticStyle:{"width":"58px","height":"58px"},attrs:{"preview-src-list":[scope.row.all_url],"fit":"cover","src":scope.row.all_url}})],1)]}}])}),_c('u-table-column',{attrs:{"prop":"name","label":"名称"}}),_c('u-table-column',{attrs:{"prop":"tag","label":"标签"}}),_c('u-table-column',{attrs:{"prop":"type_name","label":"类型"}}),_c('u-table-column',{attrs:{"prop":"create_time","label":"生成时间"}}),_c('u-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"flex"},[_c('sdvideo-goods-edit',{attrs:{"title":"编辑","id":scope.row.id},on:{"refresh":_vm.getList}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"mini"},slot:"trigger"},[_vm._v("编辑")])],1),_c('ls-dialog',{staticClass:"m-l-10 inline",attrs:{"title":"删除","content":`确认要删除?`},on:{"confirm":function($event){return _vm.onSourceDel(scope.row)}}},[_c('el-button',{attrs:{"slot":"trigger","type":"text","size":"mini"},slot:"trigger"},[_vm._v("删除 ")])],1)],1)]}}])})],1)],1),_c('div',{staticClass:"m-t-24 flex row-right"},[_c('ls-pagination',{on:{"change":_vm.getList},model:{value:(_vm.pager),callback:function ($$v) {_vm.pager=$$v},expression:"pager"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }