
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { PageMode } from '@/utils/type'
import {
	apiSdvideoGoodsAdd,
	apiSdvideoGoodsDetail,
	apiSdvideoGoodsEdit,
	apiSdvideoGoodsTypeLists,
} from '@/api/sdvideo'
@Component({
    components: {
        MaterialSelect
    }
})
export default class ImgCategoryEdit extends Vue {
	@Prop() id?: number
    @Prop({
        default: ''
    })
    title!: string //弹窗标题
    @Prop({
        default: '660px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '20vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false
    $refs!: {
        formRef: any
    }
	
    goods_tags = [
        { value: 'shang', label: '上装' },
        { value: 'xia', label: '下装' },
        { value: 'all', label: '全身装' },
    ]	
	// type
	type_list = []
    getTypeList() {
        apiSdvideoGoodsTypeLists({ page_type: 1 }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
			this.type_list = res?.lists
        })
    }		
	
    form = {
		id: 0,
        type_id: 0, 
        tag: '',  
        uri: '',
        name: '',
        prompt: '',
    }

    // 表单验证
    formRules = {
        uri: [
            {
                required: true,
                message: '请上传图片或视频',
                trigger: ['blur', 'change']
            }
        ]
    }
    /** E Data **/

    // 详情
    getDetail() {
        apiSdvideoGoodsDetail({
            id: this.form.id
        })
            .then((res: any) => {
                this.form.uri = res.uri
				this.form.type_id = res.type_id
				this.form.tag = res.tag
				this.form.name = res.name
				this.form.prompt = res.prompt
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 新增
    onSourceAdd() {
        apiSdvideoGoodsAdd(this.form)
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 编辑
    onSourceEdit() {
        apiSdvideoGoodsEdit({
            ...this.form,
            id: this.id
        })
            .then(() => {
                this.$emit('refresh')
                this.close()
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 提交
    onSubmit() {
        this.$refs.formRef.validate((valid: any) => {
            if (!valid) {
                return
            }
            if (this.form.id > 0) {
                this.onSourceEdit()
            } else {
                this.onSourceAdd()
            }
        })
    }

    // 弹窗打开触发
    onTrigger() {
        this.visible = true
		this.getTypeList()
        if (this.id) {
			this.form.id = this.id
            this.getDetail()
        }		
    }

    // 关闭弹窗
    close() {
        this.visible = false
    }
    /** E Methods **/
}
