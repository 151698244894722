
import { Component, Vue, Watch } from "vue-property-decorator";
import { apiSdvideoGoodsLists,apiSdvideoGoodsDel,apiSdvideoGoodsTypeLists } from "@/api/sdvideo";
import MaterialSelect from "@/components/material-select/index.vue";
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import SdvideoGoodsEdit from '@/components/sdvideo/sdvideo-goods-edit.vue'

@Component({
    components: {
        LsDialog,
        LsPagination,	
        MaterialSelect,
		SdvideoGoodsEdit
    },
})
export default class Source extends Vue {

    // 分页
    pager: RequestPaging = new RequestPaging()

    // 搜索表单
    searchForm = {
        type_id:'',
        tag:'',
    }

    goods_tags = [
        { value: '', label: '全部' },
        { value: 'shang', label: '上装' },
        { value: 'xia', label: '下装' },
        { value: 'all', label: '全身装' },
    ]	
	
	// type
	type_list = []
    getTypeList() {
        apiSdvideoGoodsTypeLists({ page_type: 1 }).then((res: any) => {
            res?.lists.forEach((item: any) => {
                item.sons &&
                    item.sons.forEach((sitem: any) => {
                        delete sitem.sons
                    })
            })
			let all_arr:any = [{id:0, name:'全部'}]
			this.type_list = all_arr.concat(res?.lists)
        })
    }	

    /** S Methods **/

    // 获取列表
    getList() {
        this.pager
            .request({
                callback: apiSdvideoGoodsLists,
                params: this.searchForm,
            })
            .catch((err: any) => {
                console.log('err', err)
            })
    }

    // 删除
    onSourceDel(row: any) {     
	   apiSdvideoGoodsDel({
            id: row.id
        }).then(() => {
            this.getList()
        })
    }

    // 搜索
    onSearch() {
        this.pager.page = 1
        this.getList()
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getList()
		this.getTypeList()
    }

    /** E Life Cycle **/

}
