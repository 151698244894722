var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"ls-dialog__trigger",on:{"click":_vm.onTrigger}},[_vm._t("trigger")],2),_c('el-dialog',{attrs:{"coustom-class":"ls-dialog__content","title":_vm.title,"visible":_vm.visible,"width":_vm.width,"top":_vm.top,"modal-append-to-body":false,"append-to-body":true,"center":"","before-close":_vm.close,"close-on-click-modal":false},on:{"close":_vm.close}},[_c('div',{},[_c('el-form',{ref:"formRef",attrs:{"rules":_vm.formRules,"model":_vm.form,"label-width":"120px","size":"small"}},[_c('el-form-item',{attrs:{"label":"名称"}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"类型"}},[_c('el-cascader',{attrs:{"options":_vm.type_list,"props":{
								checkStrictly: true,
								value: 'id',
								label: 'name',
								children: 'sons',
								emitPath: false
							},"clearable":""},scopedSlots:_vm._u([{key:"default",fn:function({ node, data }){return (data.level <= 2)?[_c('span',[_vm._v(_vm._s(data.name))]),(!node.isLeaf)?_c('span',[_vm._v(" ("+_vm._s(data.sons.length)+") ")]):_vm._e()]:undefined}}],null,true),model:{value:(_vm.form.type_id),callback:function ($$v) {_vm.$set(_vm.form, "type_id", $$v)},expression:"form.type_id"}})],1),_c('el-form-item',{attrs:{"label":"标签"}},[_c('el-select',{attrs:{"placeholder":"请选择标签"},model:{value:(_vm.form.tag),callback:function ($$v) {_vm.$set(_vm.form, "tag", $$v)},expression:"form.tag"}},_vm._l((_vm.goods_tags),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"图片或视频","prop":"uri"}},[_c('material-select',{attrs:{"limit":1},model:{value:(_vm.form.uri),callback:function ($$v) {_vm.$set(_vm.form, "uri", $$v)},expression:"form.uri"}})],1),_c('el-form-item',{attrs:{"label":"提示词"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.prompt),callback:function ($$v) {_vm.$set(_vm.form, "prompt", $$v)},expression:"form.prompt"}})],1)],1)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("确认")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }